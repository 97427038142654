@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  @apply flex;
}

.ant-upload-list-picture-container {
  transition: none !important;
}

.cloud-upload .ant-upload-list-picture {
  @apply !grid !grid-cols-12 !gap-4;
}

.ant-pro-core-label-tip-icon {
  @apply flex;
  @apply items-center;
  @apply hover:text-primary;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

.ant-pro-list .ant-pro-list-row.ant-pro-list-row-selected {
  background-color: #56a84615;
}

.ant-pro-list .ant-pro-list-row.ant-pro-list-row-selected:hover {
  background-color: #56a8462f;
}

.ant-form-item-label > label {
  /* display: flex !important; */
}
/*
button,
[type="button"] {
  @apply bg-primary !important;
}*/

img {
  width: 100%;
}

.ant-avatar.ant-avatar-icon {
  overflow: visible;
}

.ant-comment-actions {
  margin-top: 4px !important;
}

.ant-menu-item-icon {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-avatar-icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.notch-background {
  background-color: #212121 !important;
  @apply fixed;
  @apply z-50;
  @apply h-40;
  @apply w-screen;
  @apply -top-32;
  @apply left-0;
}

.ant-pro-card-actions > li > span a:not(.ant-btn):hover,
.ant-pro-card-actions > li > span > .anticon:hover {
  color: #56a846 !important;
}

#__next {
  height: 100vh;
}

.company-projects .ant-row {
}

.company-projects .ant-list-item-meta-avatar {
  display: none !important;
}

.ant-pagination-item-link {
  @apply justify-center;
}

.ant-page-header {
  @apply shadow-md;
}

.ant-tabs-tab-btn > span {
  @apply flex;
  @apply items-center;
  padding-right: 3px;
}

.ant-tabs-tab-btn .anticon {
  font-size: 13px;
}

.ant-form-item-label > label {
  color: #bfbfbf !important;
}

.ant-page-header-heading-extra {
  display: flex;
}

.ant-pro-form-collapse-button {
  display: flex !important;
  align-items: center !important;
}

.ant-pro-global-header-layout-mix {
  background-color: #212121 !important;
}

.ant-pro-sider.ant-layout-sider-light .ant-menu-item-selected a,
.ant-pro-sider.ant-layout-sider-light .ant-menu-item a:hover {
  color: #56a846 !important;
}

.ant-pro-menu-item {
  @apply flex;
  @apply items-center;
}

.ant-switch-inner {
  display: flex;
}

.ant-upload-list {
  margin-bottom: 16px !important;
}

/* UPLOAD */
.ant-upload-select {
  display: flex !important;
}

.ant-upload-list-picture {
  @apply grid;
  @apply grid-cols-1;
  @apply gap-4;

  @apply md:grid-cols-2;
}

.ant-upload-list::before {
  display: none !important;
}

/* MESSAGE */
.ant-message-custom-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  @apply space-x-2;
}

/* PAGINATION */
.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
}

/* BUTTON */
.ant-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  @apply space-x-3;
}

/* CARD */
.ant-pro-card-title {
  display: flex;
  @apply items-center;
}

.ant-modal-confirm-btns {
  @apply flex;
}

.sider {
  min-height: calc(100vh - 64px);
}
@media only screen and (max-width: 600px) {
  .ant-pro-list-row-content {
    display: none !important;
  }

  .ant-pro-list-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body {
    background-color: #212121 !important;
    /* background: #212121 !important;
    background: -webkit-linear-gradient(top, #212121, #f0f2f5) !important;
    background: -moz-linear-gradient(top, #212121, #f0f2f5) !important;
    background: linear-gradient(to bottom, #212121, #f0f2f5) !important; */
  }

  .sider {
    position: fixed;
    z-index: 200;
  }

  .ant-pro-page-container-children-content {
    margin: 16px 16px 0 !important;
  }
}

.iris-bg-dark {
  background-color: #212121 !important;
}

.iris-bg-light {
  background-color: #f0f2f5 !important;
}
